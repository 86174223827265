import React, { FC } from "react";
import {Switch, Route} from "react-router-dom";
import Home from "Home";
import SearchPage from "components/SearchPage";
import CustomerSearch from "components/CustomerSearch";
import EpisodeDetails from "components/EpisodeDetails";
import NotFound from "components/NotFound";
import {Descendants} from "./components/Descendants";
import {Typography} from "@mui/material";

const Routes: FC = () => {
    return (
        <Switch>
            <Route path="/episodes/:id" component={EpisodeDetails} />
            <Route exact path="/database" component={SearchPage} />
            <Route path="/database/customers/:id" component={CustomerSearch} />
            <Route exact path="/descendants" component={Descendants} />
            <Route path="/bullhead" component={() => {
                window.location.href = "https://drive.google.com/file/d/1HT_eOXzzl6gRfgGbB717j6pQaCaeGJ-e/view?usp=sharing";
                return <Typography variant="h3" textAlign="center">Redirecting...</Typography>;
            }}/>
            <Route exact path="/" component={Home} />
            <Route component={NotFound} />
        </Switch>
    );
};

export default Routes;
