import React from 'react';
import {
    CircularProgress,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: "14pt",
        color: "black",
        fontWeight: 500
    },
    'a': {
        color: 'black',
        fontWeight: 600
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const headCells = [
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'yob',
        label: 'Year of Birth',
    },
    {
        id: 'desc',
        label: 'Description from Convict Register',
    },
    {
        id: 'conviction',
        label: 'Conviction',
    },
    {
        id: 'convictNumber',
        label: 'Convict Number',
    },
    {
        id: 'camp',
        label: 'Last Recorded Convict Labor Camp',
    },
    {
        id: 'deathDate',
        label: 'Date of Death',
    },
    {
        id: 'deathCause',
        label: 'Cause of Death',
    },
    {
        id: 'deathAge',
        label: 'Estimated Age at Death',
    },
    {
        id: 'surnames',
        label: 'Common Surnames in Tree',
    },
    {
        id: 'residences',
        label: 'Common Residences in Tree (Texas Counties)',
    },
    {
        id: 'gedcom',
        label: 'GEDCOM'
    }
];


const SortTableHead = () => {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        style={{
                            fontSize: "14pt",
                            fontWeight: 800,
                            color: "black",
                            maxWidth: headCell.id === 'name' ? '25%' : 'none',
                            width: headCell.id === 'name' ? '250px' : ''
                        }}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export interface Descendant {
    name: string;
    camp: string;
    yob: string;
    desc: string;
    conviction: string;
    convictNumber: string;
    deathDate: string;
    deathCause: string;
    deathAge: number;
    surnames?: string;
    residences?: string;
    gedcom?: string;
    gedcomLink?: string;
}

export interface DescendantsTableProps {
    results: Descendant[];
}

export const DescendantsTable = ({ results }: DescendantsTableProps) => {
    if (!results) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', paddingTop: '50px'}}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <TableContainer style={{maxWidth: "1800px", minWidth: "200px", marginLeft: 'auto', marginRight: 'auto'}} component={Paper}>
            <Table size="small" aria-label="Descendants Table">
                <SortTableHead/>
                <TableBody>
                    {
                        results
                            .map((result, index) => (
                                <StyledTableRow style={{
                                    backgroundColor: result.yob ? 'white' : 'rgba(250, 243, 234, .2)',
                                    borderBottom: result.yob ? 'none' : '2px solid rgba(0, 0, 0, .5)'
                                }} key={index}>
                                    <StyledTableCell style={{fontWeight: result.yob ? 500 : 1000}}>{result.name}</StyledTableCell>
                                    <StyledTableCell>{result.yob}</StyledTableCell>
                                    <StyledTableCell>{result.desc}</StyledTableCell>
                                    <StyledTableCell>{result.conviction}</StyledTableCell>
                                    <StyledTableCell>{result.convictNumber}</StyledTableCell>
                                    <StyledTableCell>{result.camp}</StyledTableCell>
                                    <StyledTableCell>{result.deathDate}</StyledTableCell>
                                    <StyledTableCell>{result.deathCause}</StyledTableCell>
                                    <StyledTableCell>{result.deathAge}</StyledTableCell>
                                    <StyledTableCell>{result.surnames}</StyledTableCell>
                                    <StyledTableCell>{result.residences}</StyledTableCell>
                                    <StyledTableCell><a href={result.gedcomLink} target="_blank" rel="noreferrer">{result.gedcom}</a></StyledTableCell>
                                </StyledTableRow>
                            ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}