import {useEffect, useState} from 'react';
import customerRollup from '../resources/customer_rollup.json'
import * as AWS from 'aws-sdk'
import JSON5 from 'json5'

const dynamodb = new AWS.DynamoDB({
    region: 'us-east-1',
    accessKeyId: 'AKIAZIOXW7NZSDLJKNA7',
    secretAccessKey: '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' }
);
const documentClient = new AWS.DynamoDB.DocumentClient({ service: dynamodb });

export interface CustomerItem {
    customer: string;
    amt: number;
    date: string;
    dsc: string;
}

export interface CustomerWithItems {
    customerId: number;
    items: CustomerItem[];
    name: string;
    description: string;
    county: string;
    city: string;
    state: string;
}

export interface CustomerItemRollup {
    customer: string;
    customerId: number;
    sum: number;
}

export const useCustomerRollupJsonSearch = (term: string) => {
    const [loading, setIsLoading] = useState<boolean>(false);
    const [results, setResults] = useState<CustomerItemRollup[]>();

    useEffect(() => {
        if (term && term.length) {
            (async () => {
               setIsLoading(true);
               const lowercaseTerm = term.toLowerCase();
               const newResults = customerRollup.filter(rollup => rollup.customer.toLowerCase().includes(lowercaseTerm))
               setResults(newResults);
               setIsLoading(false);
            })();
        } else {
            setResults([])
        }
    }, [term]);

    return { loading, results };
}

export const useCustomerDynamoSearch = (customerId: string) => {
    const [loading, setIsLoading] = useState<boolean>(false);
    const [result, setResult] = useState<CustomerWithItems>();

    useEffect(() => {
        if (customerId != null) {
            (async () => {
                setIsLoading(true);

                const params = {
                    TableName: "tci",
                    Select: "ALL_ATTRIBUTES",
                    KeyConditionExpression: "customerId = :customerId",
                    ExpressionAttributeValues: {
                        ":customerId": customerId
                    }
                }

                documentClient.query(params, (_, data) => {
                    const unparsed = data.Items![0]
                    unparsed['items'] = JSON5.parse(unparsed['items']) as CustomerItem[]
                    setResult(unparsed as CustomerWithItems)
                    setIsLoading(false);
                })
            })();
        }
    }, [customerId]);

    return { loading, result };
};