import React from 'react';
import Routes from "Routes";
import Router from "Router";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";

import Hamburger from "./components/Hamburger";
import {ScrollToTop} from "./components/ScrollToTop";

const theme = createTheme({
    typography: {
        fontFamily: 'EB Garamond',
    },
    palette: {
        background: {
            default: "#FAF3EA"
        }
    }
});

export const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Hamburger/>
                <ScrollToTop/>
                <Routes />
            </Router>
        </ThemeProvider>
    );
}

export default App;
