import React, {useEffect, useState} from 'react';
import {
    Box,
    CircularProgress,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel
} from "@mui/material";
import {CustomerWithItems} from "hooks/searchHooks";
import {visuallyHidden} from '@mui/utils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: "14pt",
        color: "black",
        fontWeight: 500
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "white",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const headCells = [
    {
        id: 'dsc',
        label: 'Items',
    },
    {
        id: 'amt',
        label: 'Amount',
    },
    {
        id: 'date',
        label: 'Invoice Date',
    }
];

const descendingComparator = (a: any, b: any, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order: "asc" | "desc", orderBy: string) => {
    return order === 'desc'
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

interface SortTableHeadProps {
    order: "asc" | "desc",
    orderBy: string,
    onRequestSort: any
}
const SortTableHead = ({ order, orderBy, onRequestSort }: SortTableHeadProps) => {
    const createSortHandler = (property: string) => (_: any) => {
        onRequestSort(_, property);
    };
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            style={{
                                fontSize: "14pt",
                                fontWeight: 800,
                                color: "black"
                            }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export interface FullSearchTableProps {
    loading: boolean;
    result?: CustomerWithItems;
}

export const FullSearchTable = ({ loading, result }: FullSearchTableProps) => {
    useEffect(() => {
        setPage(0);
    }, [result]);

    const [order, setOrder] = useState<"asc" | "desc">("desc");
    const [orderBy, setOrderBy] = useState<string>("date");
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 25

    const onChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const onSortChange = (_: any, property: string) => {
        console.log(property);
        setPage(0);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    if (loading || !result) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', paddingTop: '50px'}}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 200 }} size="small" aria-label="TCI Table">
                <SortTableHead order={order} orderBy={orderBy} onRequestSort={onSortChange} />
                <TableBody>
                    {
                        (rowsPerPage > 0
                            ? result?.items
                                .sort(getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : result?.items
                                .sort(getComparator(order, orderBy))
                        )
                            .map((result, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{result.dsc}</StyledTableCell>
                                    <StyledTableCell>${result.amt!.toLocaleString()}</StyledTableCell>
                                    <StyledTableCell>{result.date}</StyledTableCell>
                                </StyledTableRow>
                            ))
                    }
                </TableBody>
                <TableFooter sx={{
                    color: "#40290C",
                }}>
                    <TableRow sx={{
                        color: "#40290C",
                    }}>
                        <TablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            colSpan={3}
                            count={result?.items.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={onChangePage}
                            sx={{
                                color: "#40290C",
                            }}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}