import React from 'react';
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";

export const SearchPageFooter = () => {
    return (
        <Box sx={{backgroundColor: '#734712'}} pt="50px" pb="50px">
            <div style={{maxWidth:"1200px", marginLeft: "auto", marginRight: "auto", paddingLeft: "25px", paddingRight: "15px"}}>
                <Typography variant="h4" textAlign="center" color="white">How This Database Was Created</Typography>
                <Typography variant="subtitle1" textAlign="left" color="white" fontSize="20px" mt="10px">
                    Texas Correctional Industries (TCI) sales data was obtained from the Texas Department of
                    Criminal Justice through several Texas Public Information Act requests. Customer descriptions were added by the Sugar Land Podcast team to provide context and additional search terms. Population estimates are as of July 1, 2019 and provided by the U.S. Census Bureau. Race and ethnicity statistics gleaned from the 2020 Census: Redistricting File (Public Law 94-171) Dataset. Some customer names and item descriptions were edited to make the data more reader-friendly.<br/><br/>
                    <a style={{color: 'white', fontWeight: '600'}} href="https://docs.google.com/spreadsheets/d/1eYTqKGxC7DQXWsOdi5geXAanDhWjHkGN/edit?usp=sharing&ouid=114768688111846439999&rtpof=true&sd=true" rel="noreferrer" target="_blank">
                        Click here to download a copy of this data as it appears in the database
                    </a><br/>
                    <a style={{color: 'white', fontWeight: '600'}} href="https://docs.google.com/spreadsheets/d/1khm4zO2WybzByZtfTkRFfQpqWWkVZUIbp8uGPOjQzjI/edit?usp=sharing" target="_blank" rel="noreferrer">
                        Click here to download a copy of the original data we received from the Texas Department of Criminal Justice
                    </a><br/><br/>
                    Database created by Brittney Martin and Alan Bouzek with help from Corrie Pennington and Hildy Chandler.
                </Typography>
            </div>
        </Box>
    );
}