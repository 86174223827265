import React from 'react';
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import MapIcon from '@mui/icons-material/Map';


const useStyles = makeStyles({
    container: {
        backgroundImage: 'url(https://sugarland-podcast-res.s3.amazonaws.com/home/home_2_bg.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '800px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '0 10px'
    },
    header: {
        marginLeft: '10px',
        marginRight: '10px',
        maxWidth: '800px',
    },
    subtitleContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
        marginRight: '10px',
        marginLeft: '10px',
    },
    mapIcon: {
        color: 'white',
        marginRight: '12px',
        height: '60px',
        width: '60px'
    }
})

export const HomeSlideTwo = () => {
    const classes = useStyles();
    return (
        <a href="/soiled" style={{ textDecoration: "none" }}>
            <div className={classes.container}>
                <Typography className={classes.header} variant="h3" textAlign="left" color="white" fontWeight="600">
                    Soiled: Mapping Sugar Land from settlement to development
                </Typography>
                <div className={classes.subtitleContainer}>
                    <MapIcon className={classes.mapIcon}/>
                    <Typography variant="h5" textAlign="left" color="white" sx={{maxWidth: '80%'}}>
                        See how this land changed hands over time- from slave plantations to convict lease farms to master-planned communities.
                    </Typography>
                </div>
            </div>
        </a>
    )
}

export default HomeSlideTwo;
