import React, {useEffect} from 'react';
import Carousel from 'react-material-ui-carousel'
import Episodes from "./components/Episodes";
import {HomeSlideOne} from "./HomeSlideOne";
import Subscribe from "./components/Subscribe";
import {makeStyles} from "@mui/styles";
import HomeSlideThree from "./HomeSlideThree";
import HomeSlideTwo from "./HomeSlideTwo";
import {Footer} from "./components/Footer";

const useStyles = makeStyles({
    carousel: {
        height: '800px'
    }
})

export const Home = () => {
    const classes = useStyles();

    useEffect(() => {
        document.title = "Sugar Land Podcast - Home"
    }, []);

    return (
        <>
            <Carousel animation="slide" indicators={false} autoPlay={true} swipe={false} className={classes.carousel} duration={650} interval={5000} height='800px'>
                <HomeSlideOne/>
                <HomeSlideTwo/>
                <HomeSlideThree/>
            </Carousel>
            <Subscribe/>
            <Episodes/>
            <Footer/>
        </>
    )
}

export default Home;
