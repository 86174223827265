import React, {FC} from 'react';
import {FullSearchTable} from "components/FullSearchTable";
import {useCustomerDynamoSearch} from "hooks/searchHooks";
import {useParams} from "react-router-dom";
import {Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";

export const CustomerSearch: FC = () => {
    const { id: customerId } = useParams<{ id?: string }>();
    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');
    const cleanName = name ? decodeURIComponent(name).replaceAll("+", " ") : "Search";
    const { loading, result } = useCustomerDynamoSearch(customerId!);
    const totalSpent = result && result?.items?.map(result => result.amt).reduce((a, b) => a + b, 0);
    return (
        <Box maxWidth="1800px" ml="auto" mr="auto" mt="57px" pl='25px' pr='25px'>
            <Grid container justifyContent="left" rowSpacing={2}>
                <Grid item xs={12} textAlign="left">
                    <Typography textAlign="left" fontSize="24pt" fontWeight="700" color="#40290C" variant="h5" mb="8px" style={{display: "inline-block"}}>{cleanName}</Typography>
                    <Typography textAlign="left" fontSize="24pt" fontWeight="500" color="#40290C" variant="h5" mb="8px" ml="8px" mr="8px" style={{display: "inline-block"}}>{result?.items?.length ? `$${totalSpent!.toFixed(2)} Total` : ''}</Typography>
                    <Typography textAlign="left" fontWeight="500" fontSize="14pt" color="#40290C" variant="h5" maxWidth="1200px">{result?.description || ""}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider style={{marginBottom: "7px"}} />
                    <FullSearchTable loading={loading} result={result} />
                </Grid>
            </Grid>
        </Box>

    );
};

export default CustomerSearch;
