import React, {useEffect, useState} from 'react';
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";

export interface TranscriptProps {
    transcriptPath: string;
}

export const Transcript = ({ transcriptPath }: TranscriptProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [transcript, setTranscript] = useState<string>("Loading...");
    const imageName = expanded ? 'expanded' : 'collapsed';

    useEffect(() => {
        if (expanded && transcript === "Loading...") {
            fetch(transcriptPath)
            .then((response) => response.text())
            .then(data => setTranscript(data))
        }
    }, [expanded, transcript, transcriptPath]);

    return (
        <Box mr="auto" ml="auto" mt="25px" mb="12px" width="82%" maxWidth="1250px">
            <Typography variant="h4" style={{color: "#40290C", textAlign: 'left', cursor: 'pointer', marginBottom: '10px'}} onClick={() => setExpanded(!expanded)}>
                Transcript&nbsp;
                <img src={`https://sugarland-podcast-res.s3.amazonaws.com/episodes/${imageName}.png`} width="20px" height="20px"/>
            </Typography>
            {
                expanded &&
                <Typography variant="body1" sx={{fontSize: '12pt', fontWeight: '500', whiteSpace: 'pre-wrap', lineHeight: '20px'}}>{transcript}</Typography>
            }
        </Box>
    );
}

export default Transcript;
