import React, {useCallback, useEffect, useState} from 'react';
import {Grid, IconButton, InputAdornment, styled, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {useCustomerRollupJsonSearch} from "hooks/searchHooks";
import {CustomerRollupSearchTable} from "components/CustomerRollupSearchTable";
import Aggregates from "./Aggregates";
import Typography from "@mui/material/Typography";

const BrownBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: rgb(64, 41, 12);
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: rgb(64, 41, 12);
    }
  }
`;

export const Search = () => {
    const [text, setText] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const { loading, results } = useCustomerRollupJsonSearch(searchTerm);

    const checkKeyPress = useCallback((e) => {
        const { keyCode } = e;
        if (keyCode === 13) {
            setSearchTerm(text ?? "");
        }
    },[text]);

    useEffect(() => {
        window.addEventListener("keydown", checkKeyPress)
        return ()=>{
            window.removeEventListener("keydown", checkKeyPress)
        }
    }, [checkKeyPress]);
    return (
        <Grid container spacing={2} rowSpacing={5} justifyContent="center" pt="10px" pb="10px">
            <Grid item xs={11} sm={8}>
                <BrownBorderTextField placeholder=""
                           onChange={(e) => setText(e.target.value)}
                           fullWidth
                           size="small"
                           sx={{
                               backgroundColor: "white",
                               color: "#40290C",
                           }}
                           InputProps={{
                               endAdornment: (
                                   <InputAdornment position="end">
                                       <IconButton onClick={() => setSearchTerm(text ?? "")}>
                                           <SearchIcon/>
                                       </IconButton>
                                   </InputAdornment>
                               ),
                               style: {
                                   fontSize: "14pt",
                                   color: "#40290C",
                               }
                           }}
                />
            </Grid>
            {
                (results && results.length > 0) &&
                <Grid item xs={11} sm={8}>
                    <CustomerRollupSearchTable loading={loading} results={results} />
                </Grid>
            }
            {
                (searchTerm && searchTerm.length > 0 && (!results || results.length === 0)) &&
                <Grid item xs={12}>
                    <Typography variant="h5" textAlign="center" color="#40290C" fontWeight="600" fontSize="21pt">
                        No search results found for term "{searchTerm}"
                    </Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <Aggregates/>
            </Grid>
        </Grid>
    )
}

export default Search;