import React, {useState} from 'react';
import {RelatedContent} from "./EpisodeDetails";
import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import RelatedContentGridItem from "./RelatedContentGridItem";

export interface RelatedProps {
    relatedContents: RelatedContent[];
}

export const Related = ({ relatedContents }: RelatedProps) => {
    const [expanded, setExpanded] = useState<boolean>(true);
    const imageName = expanded ? 'expanded' : 'collapsed';

    return (
        <Box mr="auto" ml="auto" mb="25px" width="82%" maxWidth="1250px">
            <Typography variant="h4" style={{color: "#40290C", textAlign: 'left', marginBottom: '30px', cursor: 'pointer'}} onClick={() => setExpanded(!expanded)}>
                Related&nbsp;
                <img src={`https://sugarland-podcast-res.s3.amazonaws.com/episodes/${imageName}.png`} width="20px" height="20px"/>
            </Typography>
            {
                expanded &&
                <Grid container justifyContent="left" alignItems="center" spacing={{ xs: 6, sm: 2, md: 4 }} >
                    {
                        relatedContents &&
                        relatedContents.map((relatedContent, index) => (
                            <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                                <RelatedContentGridItem relatedContent={relatedContent}/>
                            </Grid>
                        ))
                    }
                </Grid>
            }
        </Box>
    );
}

export default Related;
