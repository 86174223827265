import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import NotFound from "components/NotFound";
import {Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {makeStyles} from "@mui/styles";
import {RssFeedOutlined} from "@mui/icons-material";
import Related from "./Related";
import {HashLink} from "react-router-hash-link";
import Footer from "./Footer";
import Transcript from "./Transcript";

export interface EpisodeDetail {
    number: number,
    name: string,
    description: string,
    shortUrl: string,
    tagline: string,
    appleLink?: string,
    spotifyLink?: string,
    transcript: string,
    largeImageUrl: string,
    smallImageUrl: string,
    relatedContent?: RelatedContent[],
    visible: boolean
}

export interface RelatedContent {
    smallImageUrl: string,
    contentUrl: string,
    title: string,
    description: string,
    source?: string
}

const useStyles = makeStyles({
    button: {
        backgroundColor: '#6D491E',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#6D491E',
            color: '#fff',
        },
        borderRadius: '10px',
        borderColor: 'black',
        borderWidth: '1px',
        fontSize: '10pt'
    },
    buttonRound: {
        backgroundColor: '#6D491E',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#6D491E',
            color: '#fff',
        },
        borderRadius: '36px',
        borderColor: 'black',
        borderWidth: '1px',
        width: '36px !important',
        height: '36px',
        padding: 0,
        minWidth: '36px',
        maxWidth: '36px',
        marginRight: '5px'
    },
    buttonRoundImage: {
        width: '20px',
        height: '20px',
    },
    overflowHeader: {
        maxHeight: '300px',
        textOverflow: 'ellipsis',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
    }
})

export const EpisodeDetails = () => {
    const classes = useStyles();
    const { id: episodeNumber } = useParams<{ id?: string }>();
    const [details, setDetails] = useState<EpisodeDetail>();

    const socialText = encodeURIComponent(`Listen to Episode ${episodeNumber}: ${details?.name} of @sugarlandpod, an investigative podcast series presented by The Texas Newsroom ${details?.shortUrl}`)
    const emailSubject = encodeURIComponent(`Episode ${episodeNumber}: ${details?.name} of Sugar Land podcast`)
    const emailBody = encodeURIComponent(`I recommend Episode ${episodeNumber}: ${details?.name} of Sugar Land, an investigative podcast series presented by The Texas Newsroom ${details?.shortUrl}`)

    useEffect(() => {
        if (episodeNumber) {
            const num = parseInt(episodeNumber);
            const episodes: EpisodeDetail[] = require(`resources/episodes.json`);
            if (num > 0 && num <= episodes.length && episodes[num - 1].visible) {
                setDetails(episodes[num - 1]);
            }
            document.title = `Sugar Land Podcast - Episode ${episodeNumber}`
        }
    }, [episodeNumber]);

    if (!details) {
        return <NotFound />;
    }
    return (
        <>
            <Box sx={{
                backgroundImage: `url(${details.largeImageUrl})`,
                backgroundSize: "cover"
            }} width="100vw" height="110vh" maxHeight="1000px" justifyContent="bottom">
                <Box display="flex" justifyContent="center" width="82%" maxWidth="1250px" height="100%" m="auto" sx={{flexDirection: "column", color: "white"}}>
                    <Stack spacing={3}>
                        <Typography variant="h2" sx={{fontWeight: 500}}>Episode {episodeNumber}: {details.name}</Typography>
                        <Typography className={classes.overflowHeader} variant="h5">{details.description}</Typography>
                        <div>
                            <HashLink to="/#subscribe" style={{textDecoration: "none"}}><Button className={classes.button} sx={{marginRight: '10px'}}><RssFeedOutlined/>&nbsp;Subscribe</Button></HashLink>
                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${details?.shortUrl}`} target="_blank" rel="noreferrer"><Button className={classes.buttonRound}><img className={classes.buttonRoundImage} src="https://sugarland-podcast-res.s3.amazonaws.com/episodes/share-facebook.png"/></Button></a>
                            <a href={`https://twitter.com/intent/tweet?text=${socialText}`} target="_blank" rel="noreferrer"><Button className={classes.buttonRound}><img className={classes.buttonRoundImage} src="https://sugarland-podcast-res.s3.amazonaws.com/episodes/share-twitter.png"/></Button></a>
                            <a href={`mailto:?to=&subject=${emailSubject}&amp;body=${emailBody}`} target="_blank" rel="noreferrer"><Button className={classes.buttonRound}><img className={classes.buttonRoundImage} src="https://sugarland-podcast-res.s3.amazonaws.com/episodes/share-email.png"/></Button></a>
                        </div>
                        {
                            details.spotifyLink &&
                            <iframe src={details.spotifyLink}
                                    style={{
                                        borderRadius: "10px",
                                        opacity: "0.95",
                                        marginBottom: "10px",
                                        maxWidth: "1250px"
                                    }}
                                    width="100%" height="175" frameBorder="0"
                                    allow="autoplay; clipboard-write; encrypted-media; fullscreen *; picture-in-picture"
                                    loading="lazy"/>
                        }
                    </Stack>
                </Box>
            </Box>
            {
                details.transcript &&
                    <Transcript transcriptPath={details.transcript}/>
            }
            {
                details.relatedContent &&
                    <Related relatedContents={details.relatedContent}/>
            }
            <Footer/>
        </>

);
}

export default EpisodeDetails;
