import React, {useEffect, useState} from 'react';
import {Descendant, DescendantsTable} from "./DescendantsTable";
import {Grid, Typography} from "@mui/material";
import Footer from "./Footer";
import Box from "@mui/material/Box";

const filenames = ["1878-80", "1880-81", "1882", "1883", "1884", "1885", "1886-92", "1893-95", "1896-08", "1908"];

export const Descendants = () => {
    const [descendants, setDescendants] = useState<Descendant[]>([]);

    useEffect(() => {
        document.title = "Sugar Land Podcast - Finding Descendants"
        setDescendants(require(`resources/descendants.json`));
    }, []);

    return (
        <>
            <Box ml="auto" mr="auto" pl='25px' pr='25px' pt='57px' pb='60px'>
                <Grid container justifyContent="center" alignItems="center" spacing={4}>
                    <Grid item xs={12} justifyContent="center">
                        <Typography variant="h3" textAlign="center" color="#40290C" fontWeight="600">Finding Descendants of the Sugar Land 95</Typography>
                        <Typography variant="h5" textAlign="left" color="#40290C" fontWeight="600" fontSize="14pt" maxWidth="1200px" mt="18px" ml="auto" mr="auto">
                            The following are men who we believe could be buried in the Sugar Land 95 cemetery. To find their names, we cross referenced property records and convict leases to determine who was operating convict labor camps in the area immediately surrounding the cemetery throughout the lease era. We then combed through a database of convict and conduct registers to determine which men were assigned to those camps when they died.
                            Brittney Martin and Hannah Babich created public family trees for each of these men on Ancestry.com. We have also made their GEDCOM files available to download below. Our hope is that this information will make it easier for descendants of these men to find and interact with their stories.
                            <br/><br/>
                            <a style={{color: '#40290C', fontWeight: '600'}} href="https://www.ancestry.com/profile/084f7362-0006-0000-0000-000000000000" target="_blank" rel="noreferrer">View the family trees we created on Ancestry.com (subscription required)</a><br/>
                            <a style={{color: '#40290C', fontWeight: '600'}} href="https://docs.google.com/spreadsheets/d/1nhYTnE64qfHMpsYlEhqx1cO7_Yzrh5J534wZNQP2IMY/edit?usp=sharing" target="_blank" rel="noreferrer">View a list of convict labor camps in operation throughout the convict lease era</a><br/>
                            <a style={{color: '#40290C', fontWeight: '600'}} href="https://www.ancestry.com/search/collections/2143/" target="_blank" rel="noreferrer">View the database of Texas, U.S., Convict and Conduct Registers, 1875-1954</a><br/><br/>

                            If you think you might be a descendant of one of the men listed here, email us at <a style={{color: '#40290C', fontWeight: '600'}} href="mailto:findingfamily@sugarlandpodcast.com" target="_blank" rel="noreferrer" >findingfamily@sugarlandpodcast.com</a>
                        </Typography>
                        <Grid container justifyContent="center" ml="auto" mr="auto" mt="20px" spacing={1} maxWidth="1800px">
                            {filenames.map(filename => {
                                const smallUrl = `https://sugarland-podcast-res.s3.amazonaws.com/descendants/${filename}-small.jpeg`;
                                const url = `https://sugarland-podcast-res.s3.amazonaws.com/descendants/${filename}.jpeg`;
                                return (
                                    <Grid item xs={4} sm={3} md={2} lg={1}>
                                        <a href={url} target="_blank" rel="noreferrer">
                                            <img style={{display: "block", margin: "0 auto", maxWidth:"100%", width: "150px"}} src={smallUrl}/>
                                        </a>
                                    </Grid>
                                )}
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <DescendantsTable results={descendants}/>
                    </Grid>
                </Grid>
            </Box>
            <Footer/>
        </>

)
}
