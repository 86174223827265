import React, {useEffect} from 'react';
import {Grid, Typography} from "@mui/material";
import Search from "components/Search";
import Footer from "./Footer";
import {SearchPageFooter} from "./SearchPageFooter";


export const SearchPage = () => {
    useEffect(() => {
        document.title = "Sugar Land Podcast - Database"
    }, []);
    return (
        <>
            <Grid container justifyContent="center" spacing={4} pt='57px' pl='25px' pr='25px' pb='60px'>
                <Grid item xs={12} justifyContent="center" maxWidth="1500px" margin="0 auto">
                    <Typography variant="h3" textAlign="center" color="#40290C" fontWeight="600" maxWidth="1500px" ml="auto" mr="auto">How much has your community spent on prison-made goods and services?</Typography>
                    <Typography variant="h5" textAlign="left" color="#40290C" fontWeight="600" maxWidth="1400px" mt="10px" ml="auto" mr="auto">Texas is one of few states that doesn't pay incarcerated men and women anything for their work.
                        They net $50 million for the state every year providing services like school bus repair and braille transcription.
                        They make flags, desks-- even the chairs lawmakers sit in at the State Capitol. Find out what your community purchased between 2017 and 2022</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Search />
                </Grid>
            </Grid>
            <SearchPageFooter/>
            <Footer/>
        </>
    );
};

export default SearchPage;