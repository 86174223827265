import React from 'react';
import {RelatedContent} from "./EpisodeDetails";
import {Grid, Typography} from "@mui/material";


export interface RelatedContentGridItemProps {
    relatedContent: RelatedContent;
}

export const RelatedContentGridItem = ({ relatedContent }: RelatedContentGridItemProps) => {
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={7} sm={8}>
                <Typography variant="body1" sx={{fontSize: '12pt', fontWeight: '500'}}>{relatedContent.description}</Typography>
                {
                    relatedContent.source?.length &&
                    <Typography variant="caption" pb="10px" sx={{fontSize: '9pt', lineHeight: '.5em'}}>Source: {relatedContent.source}</Typography>
                }
            </Grid>
            <Grid item xs={5} sm={4}>
                <a href={relatedContent.contentUrl}><img width="100%" src={relatedContent.smallImageUrl}/></a>
            </Grid>
        </Grid>
    );
}

export default RelatedContentGridItem;
