import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {makeStyles} from "@mui/styles";
import {useWindowSize} from "../hooks/windowSize";

interface PodcastOption {
    imgPath: string;
    link: string;
}
const options: PodcastOption[] = [
    {
        imgPath: "apple-podcasts.svg",
        link: "https://podcasts.apple.com/podcast/1689403660?mt=2&at=11l79Y&ct=nprdirectory"
    },
    {
        imgPath: "spotify.svg",
        link: "https://open.spotify.com/show/7mnmqTfciOjCKBckoSuQdf?utm_medium=share&utm_source=linktree"
    },
    {
        imgPath: "google-podcasts.svg",
        link: "https://podcasts.google.com/feed/aHR0cHM6Ly9rdXRrdXR4LnN0dWRpby9jYXRlZ29yeS9zdWdhci1sYW5kL2ZlZWQ"
    },
    {
        imgPath: "stitcher.svg",
        link: "https://www.stitcher.com/show/sugar-land"
    },
    {
        imgPath: "pandora.svg",
        link: "https://www.pandora.com/podcast/sugar-land/PC:1001070487"
    },
    {
        imgPath: "pocket-casts.svg",
        link: "https://pca.st/itunes/1689403660"
    },
    {
        imgPath: "amazon-music.svg",
        link: "https://music.amazon.com/podcasts/ac95e8da-00ca-43b7-aaa8-6075b92f35cb/sugar-land"
    },
    {
        imgPath: "rss-feed.svg",
        link: "https://kutkutx.studio/category/sugar-land/feed"
    }
]

const useStyles = makeStyles({
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    }
})


export const Subscribe = () => {
    const classes = useStyles();
    const size = useWindowSize();
    return (
        <Box width="90%" sx={{backgroundColor: "#A07761", margin: "auto", marginTop: "50px", marginBottom: "50px", maxWidth: "1800px"}}>
            <Grid container justifyContent="space-evenly" alignItems="center" style={{borderWidth: "5px", borderColor: "#734712", borderStyle: "solid", paddingTop: "35px", paddingBottom: "40px", paddingLeft: "10px", paddingRight: "10px"}}>
                <Grid item xs={11} lg={5} maxHeight="600px" mb="10px">
                    <Typography variant="h2" color="white" mb="15px" style={{textAlign: 'left', fontWeight: 500}}>
                        Unearthing a sweet city's bitter history
                    </Typography>
                    <Typography variant="body1" color="white" style={{textAlign: 'justify', fontSize: '14pt'}}>
                        In 2018, construction crews building a new school in Sugar Land, Texas discovered a long-forgotten cemetery containing 95 graves. Two years ago, we set out to tell the story of these 95 people–Who were they? What happened to them? In the process, we learned that theirs is a story about power–Who gets it and how they wield it.<br/>
                    </Typography>
                </Grid>
                <Grid item xs={11} md={11} lg={6} mt="30px" mb="25px">
                    <Stack spacing={1} alignItems="center">
                        <iframe src="https://open.spotify.com/embed/episode/5EiuW4gPryAr0YKvZUipgH?utm_source=generator"
                                style={{
                                    borderRadius: "10px",
                                    maxWidth: "1250px"
                                }}
                                width="100%" height="160" frameBorder="0"
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen *; picture-in-picture"
                                loading="lazy"/>
                        <Grid id="subscribe" container rowSpacing={size.width! >= 900 ? 0 : 2} columnSpacing={1} justifyContent="space-evenly" alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h4" color="white" style={{fontWeight: 500}}>
                                    <a href="https://www.npr.org/podcasts/1177878202/sugar-land" target="_blank" rel="noreferrer" style={{textDecorationThickness: '2px', color: 'white'}}>Listen on NPR</a> or subscribe on your favorite podcasting app:
                                </Typography>
                            </Grid>
                            <Grid item xs={11} sm={6}>
                                <Grid container justifyContent="center" alignItems="center">
                                    {
                                        options.map((option, index) =>
                                            <Grid item xs={6} sm={6} key={index}>
                                                <a href={option.link} target="_blank" rel="noreferrer">
                                                    <img className={classes.image} src={`https://sugarland-podcast-res.s3.amazonaws.com/home/subscribe/${option.imgPath}`}/>
                                                </a>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Subscribe;
