import React from 'react';
import {EpisodeDetail} from "./EpisodeDetails";
import {Grid, Typography} from "@mui/material";
import {makeStyles} from '@mui/styles'
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';


export interface EpisodeGridItemProps {
    episodeDetail: EpisodeDetail;
}

const useStyles = makeStyles({
    button: {
        backgroundColor: '#6D491E',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#6D491E',
            color: '#fff',
        },
        borderRadius: '10px',
        borderColor: 'black',
        borderWidth: '1px',
        fontSize: '10pt',
        height: '36px'
    }
})

export const EpisodeGridItem = ({ episodeDetail }: EpisodeGridItemProps) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="start">
            <Grid item xs={12} sm={4}>
                <img width="100%" height="100%" style={{maxHeight: "220px", maxWidth: "300px", border: "1px solid black"}} src={episodeDetail.smallImageUrl}/>
            </Grid>
            <Grid item xs={12} sm={7}>
                <Typography variant="h5" pb="5px" sx={{fontSize: '18pt', fontWeight: '500'}}>Episode {episodeDetail.number}: {episodeDetail.name}</Typography>
                <Typography variant="body1" pb="10px" sx={{fontSize: '12pt', fontWeight: '500', lineHeight: '1.1em'}}>{episodeDetail.description}</Typography>
                <Button className={classes.button} component={Link} to={`/episodes/${episodeDetail.number}`}><PlayCircleOutlineIcon/>&nbsp;Listen</Button>
                <Button className={classes.button} component={Link} to={`/episodes/${episodeDetail.number}`} sx={{marginLeft: '5px'}}>View More</Button>
            </Grid>
        </Grid>
    );
}

export default EpisodeGridItem;
