import React from 'react';
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";


const useStyles = makeStyles({
    container: {
        pointerEvents: 'none',
        backgroundImage: 'url(https://sugarland-podcast-res.s3.amazonaws.com/home/home_1_bg.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '800px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        width: '85%',
        height: '40%',
        '@media (max-width: 600px)': {
            height: '15%'
        }
    },
    titleImage: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    logosContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100px',
        marginTop: '15px'
    },
    logos: {
        width: '160px',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    logosImage: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    }
})

export const HomeSlideOne = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <img className={classes.titleImage} src='https://sugarland-podcast-res.s3.amazonaws.com/home/home_1_title.png'/>
            </div>
            <Typography variant="h5" textAlign="center" color="white" fontWeight="600" sx={{marginLeft: '10px', marginRight: '10px'}}>
                An investigative podcast series about the 95 souls laid to rest here and the systems that buried them, presented by The Texas Newsroom.
            </Typography>
            <div className={classes.logosContainer}>
                <div className={classes.logos} style={{marginRight: '10px'}}>
                    <img className={classes.logosImage} src='https://sugarland-podcast-res.s3.amazonaws.com/home/home_1_logos_1.png'/>
                </div>
                <div className={classes.logos} style={{marginRight: '10px'}}>
                    <img className={classes.logosImage} src='https://sugarland-podcast-res.s3.amazonaws.com/home/home_1_logos_2.png'/>
                </div>
                <div className={classes.logos}>
                    <img className={classes.logosImage} src='https://sugarland-podcast-res.s3.amazonaws.com/home/home_1_logos_3.png'/>
                </div>
            </div>
        </div>
    )
}

export default HomeSlideOne;
