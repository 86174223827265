import React from 'react';
import {Box, Divider, Grid, Typography} from "@mui/material";
import {useWindowSize} from "../hooks/windowSize";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

export const Footer = () => {
    const size = useWindowSize();
    return (
        <Box id="contact" width="100%" sx={{backgroundColor: "#734712", paddingBottom: "15px", color: "white", paddingTop: "24px"}}>
            <Grid container rowSpacing={size.width! >= 900 ? 0 : 2} columnSpacing={size.width! >= 900 ? 2 : 0} justifyContent="space-around" style={{width: "90%", marginLeft: "auto", marginRight: "auto", marginBottom: "24px", paddingTop: "10px"}}>
                <Grid item xs={12} md={4} height="100%">
                    <Typography variant="h5" textAlign="left" fontWeight={500}>ABOUT</Typography>
                    <Divider color="white" style={{width: "80%", marginRight: "auto"}}/>
                    <Typography variant="subtitle2" fontSize="16px" textAlign="left" mt="10px" mb="15px">
                        The Sugar Land podcast is a production of Dot Productions and The Texas Newsroom, a public radio journalism collaboration that includes NPR, KERA in North Texas, Houston Public Media, KUT in Austin, Texas Public Radio in San Antonio and other stations across the state.
                    </Typography>
                    <Typography variant="subtitle2" fontSize="16px" textAlign="left">
                        <b>Co-hosts:</b> Brittney Martin and Naomi Reed<br/>
                        <b>Editor:</b> Rachel Osier Lindley<br/>
                        <b>Executive Producer:</b> Brittney Martin<br/>
                        <b>Engineer and Sound Designer:</b> Jacob Rosati<br/>
                        <b>Fact Checker:</b> Billy Brennan<br/>
                        <b>Audio Editor:</b> Bennett Smith<br/>
                        <b>Music:</b> JaRon Marshall<br/>
                        <b>Recording Engineer:</b> Jake Perlman<br/>
                        <b>Producer:</b> Rafa Farihah<br/>
                        <b>Managing Editor of The Texas Newsroom:</b> Corrie MacLaggan<br/>
                        <b>Website:</b> Alan Bouzek<br/>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h5" textAlign="left" fontWeight={500}>SUPPORT</Typography>
                    <Divider color="white" style={{width: "80%", marginRight: "auto"}}/>
                    <Typography variant="subtitle2" fontSize="16px" textAlign="left" mt="10px" mb="15px">
                        The podcast and website were completed with the support of a grant from Columbia University's Ira A. Lipman Center for Journalism and Civil and Human Rights with funding provided by Arnold Ventures and a grant from the Convict Leasing and Labor Project.
                    </Typography>
                    <Typography variant="subtitle2" fontSize="16px" textAlign="left">
                        The nonprofit collective Lawyers for Reporters and the SMU Dedman School of Law's First Amendment Clinic provided Dot Productions with pro bono legal assistance.
                    </Typography>
                </Grid>
                <Grid id="contact" item xs={12} md={4}>
                    <Typography variant="h5" textAlign="left" fontWeight={500}>CONTACT</Typography>
                    <Divider color="white" style={{width: "80%", marginRight: "auto"}}/>
                    <Typography variant="subtitle2" fontSize="16px" textAlign="left" fontWeight={1000} mt="10px" mb="15px">
                        <a href="mailto:brittney@sugarlandpodcast.com" target="_blank" rel="noreferrer" style={{color: "inherit", textDecoration: "none"}} >Brittney Martin</a><br/>
                        <a href="mailto:naomi@sugarlandpodcast.com" target="_blank" rel="noreferrer" style={{color: "inherit", textDecoration: "none"}}>Naomi Reed</a><br/>
                        <a href="http://www.brittneymartin.com" target="_blank" rel="noreferrer" style={{color: "inherit", textDecoration: "none"}}>Dot Productions</a><br/>
                        <a href="mailto:cmaclaggan@kera.org" target="_blank" rel="noreferrer" style={{color: "inherit", textDecoration: "none"}}>The Texas Newsroom</a><br/>
                        <a href="mailto:admin@sugarlandpodcast.com" target="_blank" rel="noreferrer" style={{color: "inherit", textDecoration: "none"}}>Web Administrator</a><br/>
                    </Typography>
                    <Grid container justifyContent="left" spacing={0}>
                        <Grid item xs={1}>
                            <a style={{color: "white"}} href="https://www.instagram.com/sugarlandpod/" target="_blank" rel="noreferrer">
                                <InstagramIcon />
                            </a>
                        </Grid>
                        <Grid item xs={1}>
                            <a style={{color: "white"}} href="https://www.facebook.com/SugarLandPod" target="_blank" rel="noreferrer">
                                <FacebookIcon />
                            </a>
                        </Grid>
                        <Grid item xs={1}>
                            <a style={{color: "white"}} href="https://twitter.com/sugarlandpod" target="_blank" rel="noreferrer">
                                <TwitterIcon />
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider color="white" />
            <Typography variant="subtitle2" fontSize="16px" textAlign="center" mt="15px">
                Copyright © Dot Productions LLC
            </Typography>
        </Box>
    );
};

export default Footer;