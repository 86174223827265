import React from 'react';

import aggregates from 'resources/aggregates.json';
import {Grid, Stack, Typography} from "@mui/material";
import {HashLink} from "react-router-hash-link";


export const Aggregates = () => {
    return (
        <Grid container justifyContent="left" columnSpacing={4} rowSpacing={6} pt="20px" pl="25px" pr="25px">
            <Grid item xs={12}>
                <Typography variant="h3" textAlign="center" color="#40290C" fontWeight="500" fontSize="24pt">Biggest Spenders by Category</Typography>
            </Grid>
            {aggregates.map((aggregate, index) =>
                <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
                    <Stack>
                        <div style={{alignItems: "center", justifyContent: "left", display: "flex"}}>
                            <img width="28px" height="28px" src={`https://sugarland-podcast-res.s3.amazonaws.com/database/${aggregate.title.toLowerCase().split(" ").join("-")}-icon.png`} alt="icon representing aggregate category"/>
                            <Typography ml="10px" variant="caption" color="#40290C" fontWeight="500" fontSize="24pt">{aggregate.title}</Typography>
                        </div>
                        {aggregate.items.map((item, index) =>
                            <Grid key={index} container justifyContent="space-between">
                                <Typography variant="caption" color="#40290C" fontWeight="500" fontSize="14pt" justifyContent="left">
                                    <HashLink style={{textDecoration: "none", color: "#40290C"}} to={`/database/customers/${item.customerId}?name=${encodeURIComponent(item.customer)}`}>
                                        <b>{item.customer}</b>
                                    </HashLink>
                                </Typography>
                                <Typography variant="caption" color="#40290C" fontWeight="500" fontSize="14pt" justifyContent="right">
                                    ${item.sum.toLocaleString()}
                                </Typography>
                            </Grid>
                        )}
                    </Stack>


                </Grid>
            )}
        </Grid>
    );
};

export default Aggregates;
