import React, {useEffect, useState} from 'react';
import {EpisodeDetail} from "./EpisodeDetails";
import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import EpisodeGridItem from "./EpisodeGridItem";

export const Episodes = () => {
    const [episodes, setEpisodes] = useState<EpisodeDetail[]>([]);

    useEffect(() => {
        setEpisodes(require(`resources/episodes.json`).filter((episode: EpisodeDetail) => episode.visible));
    }, []);

    return (
        <Box id="episodes" maxWidth="1800px" ml="auto" mr="auto" mb="50px">
            {
                episodes.length
                    ? <Grid container justifyContent="start" pr="25px" pl="25px" alignItems="start" spacing={{ xs: 4, sm: 2 }} >
                        {
                            episodes &&
                            episodes.map((episodeDetail, index) => (
                                <Grid item xs={12} lg={6} key={index}>
                                    <EpisodeGridItem episodeDetail={episodeDetail}/>
                                </Grid>
                            ))
                        }
                    </Grid>
                    : <Typography variant="h3" color="#40290C" style={{textAlign: 'center'}}>
                        Episodes Launching June 16th
                    </Typography>
            }
        </Box>
    );
}

export default Episodes;
