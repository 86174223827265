import React, {useState} from 'react';
import {Box, Drawer, IconButton, List, ListItem} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Typography from "@mui/material/Typography";
import {useWindowSize} from "../hooks/windowSize";
import {NavHashLink} from 'react-router-hash-link'

interface NavItem {
    name: string;
    path: string;
    subItems?: NavItem[]
}

const items: NavItem[] = [
    {
        name: "Home",
        path: "/"
    },
    {
        name: "Subscribe",
        path: "/#subscribe"
    },
    {
        name: "Episodes",
        path: "/#episodes"
    },
    {
        name: "Soiled: Mapping Sugar Land",
        path: "/soiled"
    },
    {
        name: "Database: Inmate-Made Goods",
        path: "/database",
    },
    {
        name: "Finding Descendants",
        path: "/descendants",
    }
]

export const Hamburger = () => {
    const size = useWindowSize();
    const [open, setOpen] = useState<boolean>(false);
    const color = (window.location.href.endsWith("/") || window.location.href.endsWith("/#subscribe") || window.location.href.endsWith("/#episodes") || window.location.href.endsWith("/#episodes") || window.location.href.includes("/episodes/"))
        ? "white"
        : "#40290C"
    const isMobile = size.width && size.width <= 844
    return (
        <div style={{width: "0", height: "0", position: "sticky", top: isMobile ? 5 : 10, left: isMobile? 5 : 10, zIndex: 2}}>
            <IconButton onClick={() => setOpen(!open)}>
                <MenuIcon sx={{color: color, transform: isMobile ? 'scale(1)' : 'scale(1.25)'}} />
            </IconButton>
            <Drawer
                anchor="left"
                open={open}
                onClose={() => setOpen(!open)}
                sx={{opacity: '95%'}}
            >
                <Box
                    sx={{
                        width: size.width && size.width > 600 ? '600px' : '350px',
                        height: '100%',
                        backgroundColor: '#A36617',
                    }}
                    role="presentation"
                    onClick={() => setOpen(!open)}
                    onKeyDown={() => setOpen(!open)}
                >
                    <List>
                        {items.map((item, index) => (
                            <ListItem key={index} disablePadding>
                                {
                                    item.path === "/soiled" ?
                                    <a href={item.path} style={{textDecoration: "none"}}>
                                        <Typography textAlign="left" ml="30px" mt="10px" sx={{color: 'white', fontSize: '24pt', fontWeight: 500}}>{item.name.toUpperCase()}</Typography>
                                    </a> :
                                        <NavHashLink smooth to={item.path} style={{textDecoration: "none"}}>
                                            <Typography textAlign="left" ml="30px" mt="10px" sx={{color: 'white', fontSize: '24pt', fontWeight: 500}}>{item.name.toUpperCase()}</Typography>
                                        </NavHashLink>
                                }
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </div>

    );
}

export default Hamburger;