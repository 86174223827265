import React from 'react';
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import SearchIcon from '@mui/icons-material/Search';
import {HashLink} from "react-router-hash-link";

const useStyles = makeStyles({
    container: {
        backgroundImage: 'url(https://sugarland-podcast-res.s3.amazonaws.com/home/home_3_bg.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '800px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '0 10px'
    },
    header: {
        marginLeft: '15px',
        marginRight: '15px',
        maxWidth: '800px'
    },
    subtitleContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
        marginRight: '10px',
        marginLeft: '10px',
    },
    searchIcon: {
        color: 'white',
        marginRight: '12px',
        height: '60px',
        width: '60px'
    }
});

export const HomeSlideThree = () => {
    const classes = useStyles();
    return (
        <HashLink to={"/database"} style={{ textDecoration: "none" }}>
            <div className={classes.container}>
                <Typography className={classes.header} variant="h3" textAlign="left" color="white" fontWeight="600">
                    Sales of Texas inmate-made goods and services net $50 million in state revenue each year.<br/>Inmates receive $0.
                </Typography>
                <div className={classes.subtitleContainer}>
                    <SearchIcon className={classes.searchIcon}/>
                    <Typography variant="h5" textAlign="left" color="white" sx={{maxWidth: '80%'}}>
                        See how much your city, school district, police department, etc. spent on inmate-made goods.
                    </Typography>
                </div>
            </div>
        </HashLink>
    )
}

export default HomeSlideThree;
